<template>
    <validation-observer ref="validationObserver">

      <!-- Publish Quiz Modal -->
      <b-modal id="publish-modal" ref="publish-modal" centered title="Publish Quiz">
        <h4>
          Are you sure you want to publish this quiz ?
        </h4>

        <template #modal-footer>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary" @click="$refs['publish-modal'].hide()">
            Cancel
          </b-button>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary" @click="publishQuiz">
            Publish
          </b-button>
        </template>
      </b-modal>
      <!-- / Publish Quiz Modal -->

      <!-- Add Images Modal -->
      <b-modal id="add-modal" ref="add-modal" size="xl" scrollable title="Add new images">
        <b-col class="images">
          <b-row align-v="stretch">
            <b-col cols="8" sm="3" v-for="(image, index) in unrelatedQuizImages" :key="image.id" class="mb-3">
              <div class="card h-100">
                <span class="show-image btn position-absolute bg-white d-flex" title="Download" @click="downloadImage(image.url)">
                  <feather-icon
                      icon="EyeIcon"
                      size="13"/>
                </span>

                <span class="add-image-checkbox position-absolute" title="Add">
                   <b-form-checkbox @change="addImage(image)"/>
                </span>

                <b-img
                    class="image-add-modal"
                    fluid
                    :src="image.url"
                    :alt="image.url"
                />

                <div class="p-1">
                  <div v-for="style in image.styles" :key="style.id" class="font-weight-bold">
                    <span class="mr-2">{{ style.name }}</span>
                    <span>{{ style.percentage }}%</span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <template #modal-footer>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary" @click="addImages">
            Add
          </b-button>
        </template>
      </b-modal>
      <!-- / Add Modal -->

      <!-- Delete Image Modal -->
      <b-modal id="delete-modal" ref="delete-modal" centered title="Delete Image">
        <h4>
          Are you sure you want to delete this image ?
        </h4>

        <template #modal-footer>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary" @click="$refs['delete-modal'].hide()">
            Cancel
          </b-button>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary" @click="deleteImage">
            Yes
          </b-button>
        </template>
      </b-modal>
      <!-- / Delete Image Modal -->

      <b-card-form>
        <div>
          <b-col class="d-flex justify-content-between">
            <div>
              <b-modal id="generate-modal" ref="generate-modal" centered title="Generate Quiz">
                <b-row>
                  <b-col

                  >
                    <b-form-group
                        label="Quiz Weight"
                        label-for="quizWeight"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required|integer"
                          name="Quiz Weight"
                      >
                        <b-form-input
                            id="quizWeight"
                            type="number"
                            v-model="quizWeight"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Quiz Weight"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <template #modal-footer>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="primary" @click="generateQuiz">
                    Generate
                  </b-button>
                </template>
              </b-modal>


              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  right
                  @click.prevent="$refs['generate-modal'].show()"
              >
                Generate new quiz images
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  right
                  @click.prevent="$refs['add-modal'].show()"
              >
                <feather-icon
                    icon="PlusIcon"
                    size="15"/>

                <span style="margin-left: 8px">Add image</span>
              </b-button>
            </div>

            <div>
              <b-button
                  v-if="imagesToDelete.length > 0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  right
                  @click.prevent="save"
              >
                Save
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  right
                  @click.prevent="$refs['publish-modal'].show()"
              >
                Publish
              </b-button>
            </div>
          </b-col>

          <b-container class="mt-3">
            <b-row>
              <b-col class="images">
                <b-row align-v="stretch">
                  <b-col cols="8" sm="4" v-for="(image, index) in quizImages" :key="image.id" class="mb-3">
                    <div class="card h-100">
                      <span class="show-image btn position-absolute bg-white d-flex" title="Download" @click="downloadImage(image.url)">
                        <feather-icon
                            icon="EyeIcon"
                            size="15"/>
                      </span>

                      <span class="close-image btn position-absolute bg-white d-flex" title="Delete" @click.prevent="openDeleteModal(image, index)">
                        <feather-icon
                          icon="XIcon"
                          size="15"/>
                      </span>

                      <b-img
                        class="image"
                        fluid
                        :src="image.url"
                        :alt="image.url"
                      />

                      <div class="p-1">
                        <div v-for="style in image.styles" :key="style.id" class="font-weight-bold">
                          <span class="mr-2">{{ style.name }}</span>
                          <span>{{ style.percentage }}%</span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="4" class="styles px-0">
                <b-table ref="projectsTable" class="position-relative text-nowrap" :items="quizStyles" responsive
                         :fields="tableColumns" show-empty empty-text="No matching records found">

                  <!-- Column: Style Name -->
                  <template #cell(styles)="data">
                    <div class="text-nowrap">
                      <span class="align-text-top font-weight-bold">{{ data.item.styleName }}</span>
                    </div>
                  </template>

                  <!-- Column: Number Of Images -->
                  <template #cell(totalImages)="data">
                    <div class="text-nowrap">
                      <span class="align-text-top font-weight-bold">{{ data.item.totalImages }}</span>
                    </div>
                  </template>

                  <!-- Column: Total Weight -->
                  <template #cell(totalWeight)="data">
                    <div class="text-nowrap">
                      <span class="align-text-top font-weight-bold">{{ data.item.totalWeight }}</span>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-card-form>
    </validation-observer>
</template>

<script>
import {
  BButton, BCol, BRow, BContainer, BImg, BTable, BFormGroup, BFormInput, BForm, BFormCheckbox
} from 'bootstrap-vue';
import BCardForm from "@/components/b-card-form";
import Ripple from "vue-ripple-directive";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, integer } from '@validations'

export default {
  components: {
    BFormCheckbox,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BTable,
    BImg,
    BCardForm,
    BButton,
    BCol,
    BRow,
    BContainer
  },
 data() {
   return {
      isLoading: false,
      quizDetails: null,
      quizStyles: [],
      quizImages: [],
      unrelatedQuizImages: [],
      tableColumns: [
       { key: 'styles', label: 'Styles', sortable: false },
       { key: 'totalImages', label: 'Images', sortable: false },
       { key: 'totalWeight', label: 'Weight', sortable: false },
      ],
      quizWeight: null,
      imageToDelete: null,
      imagesToDelete: [],
      imagesToAdd: [],
       required,
       integer,
   };
 },

  directives: {
    Ripple,
  },

  methods: {
    fetchQuizDetails() {
      this.isLoading = true;
      this.$http.get('/quizzes/Details')
          .then(response => {
            const {details , styles, images} = response.data;

            this.quizDetails = details;
            this.quizStyles = styles;
            this.quizImages = images;
          });
      this.isLoading = false;
    },

    fetchUnrelatedQuizImages() {
      this.$http.get('/quizzes/unrelatedImages')
          .then(response => {
           this.unrelatedQuizImages = response.data?.images;
          });
    },

    generateQuiz() {
      const valid = this.$refs.validationObserver
          .validate()
          .then(success => {
            if (success) {
              this.isLoading = true;
              this.$refs['generate-modal'].hide();

              this.$http.post('/quizzes/create', {
                stylesWeight: this.quizWeight
              })
                  .then(() => {

                    this.fetchQuizDetails();
                    this.fetchUnrelatedQuizImages();
                    this.isLoading = false;
                    this.quizWeight = null;

                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Quiz Generated Successfully',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                      },
                    });
                  })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Check the field validations above to be able to generate a quiz-settings!',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }
            return success
          })
      return valid
    },

    publishQuiz() {
      if (this.quizDetails) {
        this.$refs["publish-modal"].hide();
        this.$http.post(`/quizzes/${this.quizDetails?.quizId}/publish`)
            .then(() => {

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Quiz Published Successfully',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              });
            })
      }
    },

    openDeleteModal(image, index) {
      this.imageToDelete = {image, index};

      this.$refs['delete-modal'].show()
    },

    async deleteImage() {
      this.imagesToDelete.push(this.imageToDelete.image);
      this.quizImages.splice(this.imageToDelete.index, 1);
      this.$refs["delete-modal"].hide();

      this.imageToDelete = null;
    },

    addImage(image) {
      const existingImageIndex = this.imagesToAdd.findIndex(img => img.id === image.id);

      if (existingImageIndex >= 0) {
        this.imagesToAdd.splice(existingImageIndex, 1);
      } else {
        this.imagesToAdd.push(image);
      }
    },

    async addImages() {
      this.$refs["add-modal"].hide();

      await this.$http.post('/quizzes/update', {
        addImages: this.imagesToAdd,
        deleteImages: []
      });

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Images Added Successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });


      this.fetchQuizDetails();
      this.fetchUnrelatedQuizImages();
    },

    async save() {
      await this.$http.post('/quizzes/update', {
        addImages: [],
        deleteImages: this.imagesToDelete
      });

      this.imagesToDelete = [];

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Images Deleted Successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });

      this.fetchQuizDetails();
      this.fetchUnrelatedQuizImages();
    },

    downloadImage(url) {
      window.open(url);
    }
  },

  created() {
    this.fetchQuizDetails();
    this.fetchUnrelatedQuizImages();
  },
}
</script>

<style lang="scss" scoped>
.close-image {
  padding: 4px;
  right: 14px;
  top: 10px;
}

.add-image-checkbox {
  right: 14px;
  top: 10px;
}

.show-image {
  padding: 4px;
  right: 50px;
  top: 10px;
}

.card {
  box-shadow: 0px 0px 28px 0px #E9E8F1 !important;
  margin-bottom: 0;
}

.image-add-modal {
  height: 190px;
  object-fit: fill;
}

.image {
  height: 150px;
  object-fit: fill;
}
</style>