<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="HomeIcon" />
        <span>Images</span>
      </template>

      <quiz-images />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>Generate Quiz</span>
      </template>

      <generate-quiz />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BButton, BCol, BRow,
} from 'bootstrap-vue';
import GenerateQuiz from "@/views/cms/quiz-settings/GenerateQuiz.vue";
import QuizImages from "@/views/cms/quiz-settings/QuizImages.vue";

export default {
  components: {
    GenerateQuiz,
    QuizImages,
    BTabs,
    BTab,
    BButton,
    BCol,
    BRow,
  },
};
</script>
